.navbar {
  height: 100%;
}

.nav-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.logo {
  display: flex;
  max-width: 130px;
  height: 100%;
}

.logo img {
  width: 70%;
  margin-top: 18px;
}

.list-wrapper {
  display: flex;
  align-items: center;
}

.list-wrapper ul {
  top: 0;
  left: -100vw;
  z-index: 3;
  width: 100%;
  height: 122vh;
  padding-top: 140px;
  text-align: center;
  position: absolute;
  transition: all 0.5s ease-in-out;
  background-color: rgb(0, 0, 0, 0.8);
}

.list-wrapper ul li {
  list-style: none;
  margin-bottom: 15px;
}

.list-wrapper ul li a {
  display: block;
  font-size: 24px;
  padding: 10px 0;
  color: #dddddd;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.list-wrapper ul li a:hover {
  color: #ffffff;
  background-color: #49111c;
}

.list-wrapper img {
  opacity: 1;
  z-index: 4;
  width: 30px;
  right: 15px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  /* border-radius: 14px; */
  background-color: #dddddd;
  transition: all 0.5s ease-in-out;
}

@media (min-width: 800px) {
  .list-wrapper img {
    display: none;
  }

  .list-wrapper ul {
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    position: relative;
    background-color: transparent;
  }

  .list-wrapper ul li {
    display: inline-block;
    margin-bottom: 0;
  }

  .list-wrapper ul li:not(:last-child) {
    margin-right: 10px;
  }

  .list-wrapper ul li a {
    padding: 5px;
    color: #ffffff;
  }

  .list-wrapper ul li a:hover {
    padding: 5px;
    color: gray;
    transform: scale(0.8);
    background: transparent;
  }
}
