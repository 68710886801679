* {
  padding: 0;
  margin: 0;
  color: #ffffff;
}

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #49111c;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

code {
  font-family: "Montserrat", sans-serif;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1280px;
}

p {
  line-height: 30px;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New"; */
}
