footer {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}

footer img {
  max-width: 30px;
  margin: 8px;
}

footer img:not(:last-child) {
  margin-right: 10px;
}
