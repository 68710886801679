.app {
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 15px;
  min-height: 100vh;
  border-radius: 10px;
  flex-direction: column;
  background-color: #212121;
}

@media (min-width: 800px) {
  .app {
    padding: 0 100px;
    margin-top: 5vh;
    min-height: 90vh;
  }
}
