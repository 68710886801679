.home {
  flex-grow: 1;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home .title {
  width: 100%;
  font-size: 18px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.home .title p {
  line-height: 50px;
}
.title h1 p {
  color: #ffffff;
}

.home .title button {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 24px;
  margin-top: 30px;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #49111c;
  font-family: "Montserrat", sans-serif;
}

.home .person {
  width: 100%;
  margin-top: 20px;
}

.home .person img {
  width: 100%;
  /* margin: 50px; */
}

@media (min-width: 800px) {
  .home {
    flex-direction: row;
    justify-content: space-between;
  }
  .home .title,
  .home .person {
    width: 50%;
    margin-top: 0;
    text-align: left;
    font-size: 25px;
  }
  .home .person img {
    width: 80%;
    float: right;
  }
}
