/* .works {
  flex-grow: 1;
} */

.works h1 {
  margin-bottom: 20px;
}

ul li {
  margin: 20px;
}

@media (min-width: 800px) {
  .work-content {
    display: flex;
  }
}

.freelance h1 {
  margin-bottom: 20px;
}

.freelance a {
  text-decoration: none;
}

.freelance a p {
  color: #00d8ff;
}

.freelance a p:hover {
  color: #00d8ff;
  transform: scale(0.8);
}
