.contact {
  flex-grow: 1;
}

.contact h1 {
  margin-bottom: 20px;
}

.contact iframe {
  border: 0;
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
}

.contact p {
  margin-left: 20px;
}
