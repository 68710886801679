.about {
  flex-grow: 1;
}

.about h1 {
  margin-bottom: 20px;
  color: #fff;
}

.about h4 {
  font-size: 24px;
  margin-left: 20px;
}

.about p {
  color: #ffffff;
  font-size: 24px;
  padding: 5px;
  margin-left: 15px;
  line-height: 5vh;
}
