.skills {
  flex-grow: 1;
}

.skills h1 {
  margin-bottom: 20px;
}

.content {
  display: grid;
  /* font-size: 24px; */
  grid-template-columns: 1fr 1fr;
}

.content div {
  margin-bottom: 25px;
}

.content strong {
  line-height: 25px;
  padding-bottom: 15px;
  display: block;
}

.languages {
  margin-left: 20px;
}

.content li {
  margin-bottom: 10px;
  list-style-type: none;
}

@media (min-width: 800px) {
  .content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
